import { action, thunk } from 'easy-peasy';

import { getTripsRoutesSchedules, getAllRoutesSchedules, getTripsRoutesScheduleDays } from '../../services/schedules/get';

const schedulesModel = {
  routeSchedules: [],
  routeSchedulesLoading: false,
  routeSchedulesError: '',
  routeScheduleDays: [],
  getTripsRoutesSchedules: thunk(async (actions, payload) => {
    actions.setRouteSchedulesLoading(true);
    actions.setRouteSchedulesError('');
    return await getTripsRoutesSchedules(actions, payload);
  }),
  getTripsRoutesScheduleDays: thunk(async (actions, payload) => {
    actions.setRouteSchedulesLoading(true);
    actions.setRouteSchedulesError('');
    return await getTripsRoutesScheduleDays(actions, payload);
  }),
  getAllRoutesSchedules: thunk(async (actions, payload) => {
    actions.setRouteSchedulesLoading(true);
    actions.setRouteSchedulesError('');
    return await getAllRoutesSchedules(actions, payload);
  }),
  storeGetRouteSchedules: action((state, payload) => {
    state.routeSchedules = payload;
  }),
  storeGetRouteScheduleDays: action((state, payload) => {
    state.routeScheduleDays = payload;
  }),
  setRouteSchedulesLoading: action((state, payload) => {
    state.routeSchedulesLoading = payload;
  }),
  setRouteSchedulesError: action((state, payload) => {
    state.routeSchedulesError = payload;
  }),
}

export default schedulesModel;
